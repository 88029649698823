import { render, staticRenderFns } from "./product.vue?vue&type=template&id=006bca24&scoped=true"
import script from "./product.vue?vue&type=script&lang=js"
export * from "./product.vue?vue&type=script&lang=js"
import style0 from "./product.vue?vue&type=style&index=0&id=006bca24&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006bca24",
  null
  
)

export default component.exports