<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->

      <Head />
    </div>
    <div class="center" v-if="!xinwen">
      <!-- 中间 -->
      <div class="banner1"></div>
      <div class="title">
        <div class="title_1">新闻资讯</div>
        <div class="title_xian"></div>
        <div class="title_2">News Information</div>
      </div>
      <div class="news">
        <div class="new" v-for="(item, index) in list.data" :key="index" @click="ck(index)">
          <div class="img">
            <img :src="item.cover" alt="" style="width: 343px; height: 170px" />
          </div>
          <div class="n_title">
            <div class="xian"></div>
            <el-tooltip class="tooltip-content" effect="dark" :content="item.title" placement="top">
              <span>{{ item.title }}</span>
            </el-tooltip>
          </div>
          <div class="text" :title='item.introduction'>
            <div v-html="item.introduction"></div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="30"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
    <news v-if="xinwen" v-on:fanhui="fanhui($event)" :list1="list1" />
    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";
import news from "../components/newindex.vue";

export default {
  components: {
    Head,
    Foot,
    news
  },
  data() {
    return {
      list: [],
      list1: '',
      xinwen: false,
      page: 1,
      num: 30,
      total: 0
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.xinwen = this.$route.params.xinwen;
    this.list1 = this.$route.params.list1;
  },
  mounted() {
    this.getList()

  },
  methods: {
    getList() {
      this.$axios
        .post(
          "/rj/news/list",
          this.$qs.stringify({
            num: this.num,
            page: this.page,
          })
        )
        .then((res) => {
          console.log(res.data.data);
          this.list = res.data.data;
          this.total = res.data.data.total
        });
    },
    fanhui(fanhui) {
      this.xinwen = fanhui;
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    ck(a) {
      this.xinwen = true;
      this.list1 = this.list.data[a];
      console.log(this.list1)
    }
  },
};
</script>
<style scoped lang="scss">
.center {
  margin: 0 auto;
  margin-bottom: 90px;

  .news {
    width: 1181px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .new {
      text-align: left;
      margin-top: 15px;
      cursor: pointer;
      margin-bottom: 50px;

      .img {
        width: 343px;
        height: 170px;
      }

      .n_title {
        padding-left: 5px;
        display: flex;
        margin-top: 15px;
        width: 321px;
        height: 27px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        .xian {
          width: 3px;
          height: 23px;
          background: #f47506;
          position: absolute;
          left: 0px;
          top: 3px;

        }
      }

      .text {
        margin-top: 15px;
        width: 332px;
        height: 92px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7b7b7b;
        line-height: 24px;

        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        // -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

  }

  .news::after {
    content: '';
    width: 343px;
    height: 0;
    visibility: hidden;
  }
}

.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}

.title {
  margin-top: 50px;
  margin-bottom: 50px;

  .title_1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #272727;
  }

  .title_xian {
    margin: 0 auto;
    margin-top: 5px;
    width: 57px;
    height: 2px;
    background: #272727;
  }

  .title_2 {
    margin-top: 5px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    color: #828282;
  }
}

.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}

.head1 {
  text-align: center;
  background: #ffffff;
}

.pagination {
  width: 1181px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}
</style>
<style>
.el-tooltip__popper {
  background: rgba(78, 78, 78, 0.737) !important;
}

.el-tooltip__popper[x-placement^=top] .popper__arrow:after {
  border-top-color: rgba(78, 78, 78, 0.737) !important;
}
</style>