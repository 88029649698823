<template>
  <!-- 底部 -->
  <div class="foot">
    <div class="index">
      <div class="index_1">
        <div class="left">
          <div class="title">联系我们/CONTACT US</div>
          <div class="text">
            地址：杭州市拱墅区祥园路30号乐富智汇园<br />
            手机：189-6809-3234<br />
            邮编：310011<br />
            邮箱：2981322179@qq.com <br />
            网址：www.hzrjkj.cn
          </div>
          <div class="img"><img src="../assets/23.png" alt="" /></div>
        </div>
        <div class="center">
          <baidu-map class="bm-view" ak="lIXC8d0tvmYlv7FjVKIhrCRo5pDbf8RO" :center="center" :zoom="zoom"
            @ready="handler">
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
              :autoLocation="true"></bm-geolocation>
            <bm-marker :position="{ lng: 120.118096, lat: 30.340524 }" :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE">
              <bm-label content="杭州融健科技有限公司" :labelStyle="{ color: 'red', fontSize: '1px' }"
                :offset="{ width: 19, height: 0 }" />
            </bm-marker>
          </baidu-map>

          <!-- <baidu-map
            class="bm-view"
             ak="lIXC8d0tvmYlv7FjVKIhrCRo5pDbf8RO"
            :center="{ lng: 120.1202, lat: 30.3413 }"
            :zoom="18"
          >
            <bm-marker

              :position="{ lng: 120.1202, lat: 30.3413 }"
              :dragging="true"



            >
              <bm-label
                content="杭州融健科技"
                :labelStyle="{ color: 'red', fontSize: '1px' }"
                :offset="{ width: 19, height: -10 }"
              />
            </bm-marker>
          </baidu-map> -->
        </div>
        <div class="right">
          <div class="r_img"><img src="../assets/15.fba42e6.png" alt="" /></div>
          <div class="r_text">微信公众号</div>
        </div>
      </div>
    </div>

    <div class="renzhen">

      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="   font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;text-decoration:none">CopyRight © 2019-{{ year }} 杭州融健科技有限公司 版权所有 All rights reserved
        浙ICP备16047220号</a>
    </div>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
export default {
  components: {
    BaiduMap,
  },
  name: "foot",
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      year: new Date().getFullYear()
    };
  },
  mounted() {
    console.log(this.year)
  },
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 120.118096;
      this.center.lat = 30.340524;
      this.zoom = 19;
      map.enableScrollWheelZoom();
    },
  },
};
</script>
<style scoped lang="scss">
.foot {
  height: 400px;
  margin: 0 auto;

  .index {
    height: 338px;
    margin-top: 38px;
    background: #212121;

    .index_1 {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .left {
        color: #ffffff;

        .title {
          text-align: left;
          margin-top: 39px;
          width: 244px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }

        .text {
          width: 321px;
          height: 136px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          margin-top: 31px;

          text-align: left;
        }

        .img {
          margin-top: 38px;
          text-align: left;
        }
      }

      .center {
        width: 507px;
        height: 243px;

        .bm-view {
          margin-top: 38px;
          width: 100%;
          height: 243px;
        }
      }

      .right {
        height: 243px;
        margin-top: 38px;

        .r_text {
          margin-top: 19px;
          color: #ffffff;
        }
      }
    }
  }

  .renzhen {
    background: #101010;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding-top: 25px;
    height: 38px;
  }
}
</style>
