<template>
  <!-- 头部 -->
  <!-- <div class="n_index">
    <div v-on:click="fanhui">返回</div>
    <div>
        这里是新闻详情
    </div>

  </div> -->

  <div class="diyi">
    <div class="head">
      <div class="h_btn" v-on:click="fanhui">返回列表</div>
      <div class="title">{{ list1.title }}</div>
      <div class="xian"></div>
      <div class="time">
        发布时间：{{ list1.startTime }}
      </div>
      <img v-if="list1.id > 8" :src="list1.cover" alt="" style="margin: 0px auto; width: 500px; margin-bottom: 30px" />
      <div class="text" v-html="detail"></div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: ["list1"],
  name: "news",
  data() {
    return {
      xianshi: false,
      detail: ''
    };
  },
  mounted() {
    this.$axios
      .post(
        "/rj/news/info",
        this.$qs.stringify({
          id: this.list1.id,
        })
      )
      .then((res) => {
        console.log(res.data.data);
        this.detail = res.data.data.origin
      });
  },
  methods: {
    fanhui() {
      this.$emit("fanhui", this.xianshi);
    },
  },
};
</script>
<style scoped lang="scss">
.diyi {
  margin: 0 auto;
  width: 1181px;

  .head {
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .h_btn {
      width: 83px;
      height: 26px;
      background: #f47506;
      border-radius: 2px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      padding-top: 6px;
    }

    .title {
      height: 25px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #272727;
      margin: 0 auto;
      margin-bottom: 30px;

    }

    .time {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      color: #272727
    }

    .xian {
      width: 1180px;
      height: 1px;
      background: #f47506;
      margin-bottom: 30px;
    }

    .text {
      text-indent: 36px;
      width: 1107px;

      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #4d4d4d;
      margin: 0 auto;
      line-height: 30px;
      text-align: left;
    }
  }

  .d_index {
    width: 100%;

    .biao {
      display: flex;
      margin-bottom: 30px;

      .yuan {
        width: 15px;
        height: 15px;
        background: #0665da;
        border-radius: 50%;
        margin-top: 8px;
        margin-right: 10px;
      }

      .b_title {
        width: 74px;
        height: 18px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a2a2a;
        line-height: 30px;
      }
    }

    .anli {
      width: 100%;
      margin-bottom: 30px;

      .anli_1 {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        align-items: center;

        .img1 {
          width: 387px;
          height: 221px;
        }

        .img2 {
          width: 156px;
          height: 84px;
        }

        .anli_text {
          width: 548px;
          height: 106px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 30px;
        }
      }

      .anli_2 {
        display: flex;
        justify-content: space-between;

        .anli_center {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .wentu {
            .img_title {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1889c9;
              line-height: 30px;
            }
          }
        }

        .anli_right {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .wenzhi {
            width: 556px;
            height: 40px;
            background: #f0f0f0;
            padding: 20px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2b2b2b;
          }
        }
      }
    }
  }
}
</style>
