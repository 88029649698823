<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->
      <Head />
    </div>
    <div class="center">
      <!-- 中间 -->
      <div class="banner1"></div>
      <div class="title">
        <div class="title_1">公司简介</div>
        <div class="title_xian"></div>
        <div class="title_2">Company Profile</div>
      </div>

      <div class="tuwen">
        <div class="img"><img src="../assets/组 5.png" alt="" /></div>
        <div class="text">
          <span style="font-size: 24px; font-weight: 400; color: #f47506"
            >杭</span
          ><span
            >&nbsp;州融健科技有限公司,
            2016年年底成立，是一家从事面向养老与医疗方面提供IOT、大数据、AI产品的高科技公司。公司由浙大校友领衔，以及多位海内外高层次技术人士和养老专家组成核心团队，共同致力于智慧生命体征监测、医养护智慧养老综合解决方案，截至目前已经获得多项知识产权。
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过“互联网+养老”服务途径，特别在智慧养老安全报警领域方面积累了大量经验，并已经形成了独特技术优势和服务。在智慧养老安全报警领域方面，不仅仅研发出超高性价比的智能终端（心率、呼吸、离床监测、主动呼叫+手环），而且提供智慧养老管理系统（SAAS）平台,构建养老机构日常管理、居家养老服务站的服务管理、旅居养老的健康管理，满足老人多元化需求。目前为浙江、江西省市多家养老机构、居家养老服务站、健康养老基地提供技术服务。</span
          >
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";

export default {
  components: {
    Head,
    Foot,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.title {
  margin-top: 50px;
  margin-bottom: 50px;
  .title_1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #272727;
  }
  .title_xian {
    margin: 0 auto;
    margin-top: 5px;
    width: 57px;
    height: 2px;
    background: #272727;
  }
  .title_2 {
    margin-top: 5px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    color: #828282;
  }
}
.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}
.head1 {
  text-align: center;
  background: #ffffff;
}
.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}
.center {
  margin: 0 auto;
  margin-bottom: 90px;
  .tuwen {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .img {
      width: 515px;
      height: 327px;
    }
    .text {
      width: 620px;
      height: 294px;
      text-align: left;
      // font-size: 24px;
      //       font-weight: 400;
      color: #696969;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}
</style>
