import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '../views/index.vue'
import about from '../views/about.vue'
import news from '../views/news.vue'
import product from '../views/product.vue'
import contact from '../views/contact.vue'
import platforms from '../views/platforms.vue'
// import pdfView from '../views/pdfView.vue'
import hardware from '../views/hardware.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: hardware
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/platforms',
    name: 'platforms',
    component: platforms
  },
  // {
  //   path: '/pdfView',
  //   name: 'pdfView',
  //   component: pdfView
  // },
]

const router = new VueRouter({
  routes
})

export default router
