<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->

      <Head />
    </div>
    <div class="center">
      <!-- 中间 -->

      <div class="banner1"></div>

      <div class="jianjie">
        <div class="title">
          <div class="title_1">公司简介</div>
          <div class="title_xian"></div>
          <div class="title_2">Company Profile</div>
        </div>

        <div class="index">
          <div class="img">
            <img src="../assets/13.png" alt="" />
          </div>
          <div class="text" :class="{ test: true, testenter: testShow }">
            <div class="text_title">
              <div class="text_title1">杭州融健科技有限公司</div>
              <div class="xian"></div>
              <div class="mor" @click="go()">了解更多></div>
            </div>
            <div class="text_index">
              杭州融健科技有限公司,
              2016年年底成立，是一家从事面向健康养老领域提供智慧养老系统和智能IOT硬件的的高科技公司。公司由浙大校友领衔，以及多位海内外高层次技术人士和养老专家组成核心团队，共同致力于智慧养老一体化平台及综合解决方案，截至目前已经获得多项知识产权。
              通过“互联网+养老”服务路径，和五年在智慧养老领域的深耕探索，已经形成了“五大系统一个中心”的软件产品体系：机构养老管理系统、居家养老管理系统、日间照料管理系统综合业务...
            </div>
          </div>
        </div>
      </div>
      <div class="banner2">
        <img src="../assets/12.png" alt="" class="img" />
      </div>
      <div class="fuwu">
        <div class="title">
          <div class="title_1">服务群体</div>
          <div class="title_xian"></div>
          <div class="title_2">Service Group</div>
        </div>
        <div class="fuwu_text">
          通过信息化和数字化，公司为社区医院、健康管理机构、基因检测机构、中医药馆等机构定制化提供数据系统。随着大数据和AI技术的不断积累和深度研究，公司将会不断推出新的科技产品和服务，为我国医疗健康养老事业的发展做出应有的贡献
        </div>
        <div class="fuwu_kuang">
          <div class="fuwu_kuang1" :class="{ test: true, testenter: testShow1 }">
            <div class="img">
              <img src="../assets/14.png" alt="" />
            </div>

            <div class="text">
              <div class="text_title">C端服务</div>
              <div class="text_index">
                以家庭为核心、社区为依托、通过专业化服务为老人提供医、护、养结合的高质量服务，提升老人及家属幸福感。
              </div>
            </div>
          </div>

          <div class="fuwu_kuang2" :class="{ test: true, testenter: testShow2 }">
            <div class="img">
              <img src="../assets/15.png" alt="" />
            </div>

            <div class="text">
              <div class="text_title">B端服务</div>
              <div class="text_index">
                为养老机构/社区/商家打造信息化系统，精准匹配服务与需求，实现精细化管理，提高服务效率与品质。
              </div>
            </div>
          </div>

          <div class="fuwu_kuang3" :class="{ test: true, testenter: testShow3 }">
            <div class="img">
              <img src="../assets/16.png" alt="" />
            </div>

            <div class="text">
              <div class="text_title">G端服务</div>
              <div class="text_index">
                为政府提供可视化监管平台，便于资源及资金统一调配，打通数据孤岛，实现信息共享。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pingtai">
        <div class="title" style="padding-top: 40px;">
          <div class="title_1">智慧养老平台</div>
          <div class="title_xian"></div>
          <div class="title_2">Smart retired Platform</div>
        </div>
        <img src="../assets/pingtai.png" style="width: 1181px;margin-bottom: 40px">
      </div>
      <div class="yingyong">

        <div class="title">
          <div class="title_1">应用系统</div>
          <div class="title_xian"></div>
          <div class="title_2">Application System</div>
        </div>
        <div
          style="display:flex;justify-content: flex-end; font-size: 20px;cursor:pointer;color: #189EF1;align-items: center"
          @click="() => { this.$router.push({ name: 'product', params: { id: 4 } }) }">more >></div>
        <div class="yingyong_index">
          <div class="yy_oll" v-for="(item, index) in casesList.data" :key="index">
            <div class="yy_img">
              <img :src="item.cspic" alt="" class="yy_im" />
            </div>
            <div class="yy_title" style="height: 134px">
              <div class="yy_1" style="padding-top: 20px">{{ item.title }}</div>
              <div class="yy_detail">{{ item.discribe }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner3">
        <div class="shebei">
          <div class="title" style="margin-top: 40px; margin-bottom: 40px">
            <div class="title_1" style="color: #ffffff">智能设备</div>
            <div class="title_xian" style="background: #ffffff"></div>
            <div class="title_2" style="color: #ffffff">Smart Device</div>
          </div>
          <img src="../assets/zhinengyingjian.png" style="width: 1181px;padding-bottom: 40px">
          <!--<div class="oll">-->
          <!--  <div class="oll_index"  @click="go1(2)">-->
          <!--    <div class="img"><img src="../assets/t16.png" alt="" style="width: 70%;" /></div>-->
          <!--    <div class="oll_title" style="margin-top: 0px">一键呼叫</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index" @click="go1(1)">-->
          <!--    <div class="img">-->
          <!--      <img src="../assets/t15.png" alt="" style="width: 34%;" />-->
          <!--    </div>-->
          <!--    <div class="oll_title" >智能网关</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(3)">-->
          <!--    <div class="img">-->
          <!--      <img src="../assets/t17.png" alt="" style="width: 50%;" />-->
          <!--    </div>-->
          <!--    <div class="oll_title">红外人体感应</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(4)">-->
          <!--    <div class="img">-->
          <!--      <img src="../assets/t18.png" alt="" style="margin-top: 50px" />-->
          <!--    </div>-->
          <!--    <div class="oll_title" >智能离床带</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(6)">-->
          <!--    <div class="img"><img src="../assets/t23.png" alt="" style="width: 65%;" /></div>-->
          <!--    <div class="oll_title">毫米波智能睡眠监测仪</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(7)">-->
          <!--    <div class="img"><img src="../assets/t24.png" alt="" style="margin-top: 20px" /></div>-->
          <!--    <div class="oll_title" style="margin-left: 19px">-->
          <!--      雷达波人体跌倒监测仪-->
          <!--    </div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(5)">-->
          <!--    <div class="img"><img src="../assets/t19.png" alt="" /></div>-->
          <!--    <div class="oll_title">智能手环</div>-->
          <!--  </div>-->
          <!--  <div class="oll_index"  @click="go1(8)">-->
          <!--    <div class="img">-->
          <!--      <img src="../assets/t25.png" alt="" style="margin-top: 20px" />-->
          <!--    </div>-->
          <!--    <div class="oll_title">智慧养老机器人</div>-->
          <!--  </div>-->
          <!--</div>-->
        </div>
      </div>
      <div class="news-container">
        <div class="title">
          <div class="title_1">新闻资讯</div>
          <div class="title_xian"></div>
          <div class="title_2">News Information</div>
        </div>
        <div
          style="display:flex;justify-content: flex-end; font-size: 20px;cursor:pointer;color: #189EF1;align-items: center"
          @click="() => { this.$router.push({ name: 'news', params: { id: 3 } }) }">more >></div>
        <div class="news-content">
          <div class="new" v-for="(item, index) in list.data" :key="index" @click="ck(index)">
            <div class="img">
              <img :src="item.cover" alt="" style="width: 343px; height: 170px" />
            </div>
            <div class="n_title">
              <div class="xian"></div>
              <el-tooltip class="tooltip-content" effect="dark" :content="item.title" placement="top">
                <span>{{ item.title }}</span>
              </el-tooltip>

            </div>
            <div class="text" :title='item.introduction'>
              <div v-html="item.introduction"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";

export default {
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      currentScroll: null,
      testShow: false,
      testShow1: false,
      testShow2: false,
      testShow3: false,
      list: [],
      casesList: [],
      list1: '',
      list2: ''
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$axios
      .post(
        "/rj/news/list",
        this.$qs.stringify({
          num: 6,
          page: 1,
        })
      )
      .then((res) => {
        this.list = res.data.data

      });
    this.$axios
      .post(
        "/rj/cases/list",
        this.$qs.stringify({
          num: 6,
          page: 1,
        })
      )
      .then((res) => {
        this.casesList = res.data.data

      });
  },
  methods: {
    ck(a) {
      this.list1 = this.list.data[a];
      this.$router.push({ name: 'news', params: { id: 3, xinwen: true, list1: this.list1 } });
    },
    go1(e) {
      this.list2 = this.casesList.data[e];
      console.log(this.list2, '12121')
      this.$router.push({ name: 'product', params: { id: 4, product: true, bianhao: e, list1: this.list2 } });
    },
    go() {
      this.$router.push({ name: 'about', params: { id: 2 } });
    },
    handleScroll() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      // console.log(this.currentScroll);
      if (this.currentScroll >= 100) {
        this.testShow = true;
      }
      if (this.currentScroll >= 1300) {
        this.testShow1 = true;
      }
      setTimeout(() => {
        if (this.currentScroll >= 1300) {
          this.testShow2 = true;
        }
      }, 400);

      setTimeout(() => {
        if (this.currentScroll >= 1300) {
          this.testShow3 = true;
        }
      }, 800);
    },
  },
};
</script>
<style scoped lang="scss">
.yy_detail {
  text-align: left;
  margin: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #929292;
}

.testenter {
  // transform: translateZ(0px)!important;  //滚动后的位置
  opacity: 1 !important; //滚动后显现

  transition: all 1s ease;
}

.test {
  // transform: translateZ(500px);  //滚动前的位置

  opacity: 0; //滚动前设置透明隐藏
}

.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}

.head1 {
  text-align: center;
  background: #ffffff;
}

.center {
  width: 100%;
  margin: 0 auto;

  .jianjie {
    width: 1181px;
    height: 600px;
    margin: 0 auto;

    .index {
      display: flex;
      position: relative;

      .text {
        position: absolute;
        top: 67px;
        right: 0px;

        width: 617px;
        height: 265px;
        background: #ffffff;
        box-shadow: -3px 2px 10px 0px rgba(49, 49, 49, 0.16);
        padding-left: 38px;

        .text_title {
          text-align: left;
          position: revert;
          margin-bottom: 30px;

          .text_title1 {
            margin-top: 51px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #212121;
          }

          .xian {
            margin-top: 10px;
            width: 42px;
            height: 2px;
            background: #2f2f2f;
          }

          .mor {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f47506;
            position: absolute;
            right: 20px;
            top: 55px;
            cursor: pointer;
          }
        }

        .text_index {
          width: 601px;
          height: 86px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #868686;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  }
}

.title {
  margin-top: 50px;
  margin-bottom: 50px;

  .title_1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #272727;
  }

  .title_xian {
    margin: 0 auto;
    margin-top: 5px;
    width: 57px;
    height: 2px;
    background: #272727;
  }

  .title_2 {
    margin-top: 5px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    color: #828282;
  }
}

.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}

.banner2 {
  height: 208px;
  margin: 0 auto;

  .img {
    width: 100%;
  }
}

.fuwu {
  width: 1181px;
  height: 850px;
  margin: 0 auto;

  .fuwu_text {
    width: 1172px;
    height: 41px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4e4e4e;
    line-height: 24px;
    margin-bottom: 50px;
  }

  .fuwu_kuang {
    width: 100%;
    height: 548px;
    display: flex;
    justify-content: space-between;

    .fuwu_kuang1 {
      width: 343px;
      height: 100%;
      position: relative;

      // background-image: url(../assets/14.png);
      .text {
        position: absolute;
        bottom: 0px;
        text-align: left;
        padding: 30px;
        background: #ffffff;

        .text_title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2c2c2c;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .text_index {
          width: 283px;
          height: 72px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8f8f8f;
          line-height: 24px;
        }
      }
    }

    .fuwu_kuang2 {
      width: 343px;
      height: 100%;
      position: relative;

      // background-image: url(../assets/14.png);
      .text {
        position: absolute;
        bottom: 0px;
        text-align: left;
        padding: 30px;
        background: #ffffff;

        .text_title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2c2c2c;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .text_index {
          width: 283px;
          height: 72px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8f8f8f;
          line-height: 24px;
        }
      }
    }

    .fuwu_kuang3 {
      width: 343px;
      height: 100%;
      position: relative;

      // background-image: url(../assets/14.png);
      .text {
        position: absolute;
        bottom: 0px;
        text-align: left;
        padding: 30px;
        background: #ffffff;

        .text_title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2c2c2c;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .text_index {
          width: 283px;
          height: 72px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8f8f8f;
          line-height: 24px;
        }
      }
    }

    .fuwu_kuang1:hover {
      box-shadow: 0px 0px 10px 8px rgba(49, 49, 49, 0.16);

      .text_title {
        color: #f47506;
      }
    }

    .fuwu_kuang2:hover {
      box-shadow: 0px 0px 10px 8px rgba(49, 49, 49, 0.16);

      .text_title {
        color: #f47506;
      }
    }

    .fuwu_kuang3:hover {
      box-shadow: 0px 0px 10px 8px rgba(49, 49, 49, 0.16);

      .text_title {
        color: #f47506;
      }
    }
  }
}

.banner3 {
  width: 100%;
  height: 777px;
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
  display: flex;
  justify-content: center;
  background-image: url(../assets/18.png);

  .shebei {
    width: 1181px;
    margin: 0 auto;

    .oll {
      width: 100%;
      height: 610px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .oll_index {
        width: 251px;
        height: 251px;
        background: #ffffff;
        border-radius: 20px;
        position: relative;
        transition: all 0.6s;

        .img {
          margin-top: 20px;
          height: 170px;
        }

        .oll_title {
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #252525;
        }
      }

      .oll_index:hover {
        transform: scale(1.1);
      }
    }
  }
}

.pingtai {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: white;
}

.yingyong {
  width: 1181px;
  height: 940px;
  margin: 0 auto;

  .yingyong_index {
    width: 100%;
    height: 540px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .yy_oll {
      width: 343px;
      height: 340px;
      overflow: hidden;
      margin-top: 30px;

      .yy_img {
        width: 343px;
        height: 216px;
        overflow: hidden;

        .yy_im {
          transition: all 1s;
          width: 100%;
          height: 100%;
        }

        .yy_im:hover {
          transform: scale(1.2);
        }
      }

      .yy_title {
        width: 343px;
        height: 124px;
        background: #ffffff;

        .yy_1 {
          padding-top: 14px;
          // margin-left: 23px;
          margin-left: 10px;
          text-align: left;
          height: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #242424;
        }

        .yy_2 {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          .yy_text {
            display: flex;
            margin-right: 44px;
            margin-top: 13px;
            text-align: left;

            .yy_dian {
              margin-top: 3px;
              margin-right: 11px;

              width: 11px;
              height: 11px;
              background: #f47506;
              border-radius: 50%;
            }

            .y_text {
              width: 100px;
              height: 14px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #929292;
            }
          }
        }
      }
    }
  }
}

.news-container {
  width: 1181px;
  margin: 0 auto;

  .news-content {
    width: 1181px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .new {
      text-align: left;
      margin-top: 15px;
      cursor: pointer;
      margin-bottom: 50px;

      .img {
        width: 343px;
        height: 170px;
      }

      .n_title {
        padding-left: 5px;
        display: flex;
        margin-top: 15px;
        width: 321px;
        height: 27px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        .xian {
          width: 3px;
          height: 23px;
          background: #f47506;
          position: absolute;
          left: 0px;
          top: 3px;

        }
      }

      .text {
        margin-top: 15px;
        width: 332px;
        height: 92px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7b7b7b;
        line-height: 24px;

        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        // -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
<style>
.el-tooltip__popper {
  background: rgba(78, 78, 78, 0.737) !important;
}

.el-tooltip__popper[x-placement^=top] .popper__arrow:after {
  border-top-color: rgba(78, 78, 78, 0.737) !important;
}
</style>