<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->

      <Head />
    </div>
    <div class="center" v-if="!product">
      <!-- 中间 -->
      <div class="banner1"></div>
      <div class="title">
        <div class="title_1">产品体系</div>
        <div class="title_xian"></div>
        <div class="title_2">Product System</div>
      </div>
      <div class="proList">
        <div class="proItem" v-for="(item, index) in list.data" :key="index">
          <img :src="item.cspic" alt="" />
          <div class="text">
            <div class="yy_1" style="padding-top: 20px">{{ item.title }}</div>
            <div class="yy_detail">{{ item.discribe }}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="30"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
    <product v-if="product" v-on:fanhui="fanhui($event)" :list1="list1" />

    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";
import product from "../components/productindex.vue";

export default {
  components: {
    Head,
    Foot,
    product
  },
  data() {
    return {
      list: [],
      product: false,
      list1: '',
      page: 1,
      num: 30,
      total: 0
    };
  },
  mounted() {
    // console.log(this.$route.params.bianhao);
    this.product = this.$route.params.product;
    this.list1 = this.$route.params.list1;
    this.getList()
  },
  methods: {
    getList() {
      this.$axios
        .post(
          "/rj/cases/list",
          this.$qs.stringify({
            num: this.num,
            page: this.page,
          })
        )
        .then((res) => {
          console.log(res.data.data);
          this.list = res.data.data;
          this.total = res.data.data.total
        });
    },
    fanhui(fanhui) {
      this.product = fanhui;
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    ck(a) {
      this.product = true;
      this.list1 = this.list.data[a];
      console.log(this.list1)
    }
  },
};
</script>
<style scoped lang="scss">
.center {
  width: 100%;

  .title {
    margin-top: 50px;
    margin-bottom: 50px;

    .title_1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #272727;
    }

    .title_xian {
      margin: 0 auto;
      margin-top: 5px;
      width: 57px;
      height: 2px;
      background: #272727;
    }

    .title_2 {
      margin-top: 5px;
      font-size: 18px;
      font-family: Arial;
      font-weight: 400;
      color: #828282;
    }
  }

  .proList {
    display: flex;
    flex-wrap: wrap;
    width: 1181px;
    margin: 0 auto;
    justify-content: space-between;

    .proItem {
      width: 343px;
      height: 380px;
      overflow: hidden;
      margin-top: 30px;

      img {
        width: 343px;
        height: 246px;
        overflow: hidden;
      }

      .text {
        width: 343px;
        height: 124px;
        background: #ffffff;

        .yy_1 {
          padding-top: 14px;
          // margin-left: 23px;
          margin-left: 10px;
          text-align: left;
          height: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #242424;
        }

        .yy_detail {
          text-align: left;
          margin: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #929292;
        }
      }
    }
  }

  .proList::after {
    content: '';
    width: 343px;
    height: 0;
    visibility: hidden;
  }
}

.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}

.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}

.head1 {
  text-align: center;
  background: #ffffff;
}

.pagination {
  width: 1181px;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
}
</style>
