<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->
      <Head />
    </div>
    <div class="center">
      <!-- 中间 -->
      <div class="banner1"></div>
      <div class="title">
        <div class="title_1">联系我们</div>
        <div class="title_xian"></div>
        <div class="title_2">Contact Us</div>
      </div>

      <div class="nei">
        <div class="left">
          <div class="title">杭州融健科技有限公司</div>
          <div class="text">
            地址：杭州市拱墅区祥园路30号乐富智汇园<br />
            手机：189-6809-3234<br />
            邮编：310011<br />
            邮箱：2981322179@qq.com <br />
            网址：www.hzrjkj.cn
          </div>
        </div>

        <div class="n_center">
          <baidu-map
            class="bm-view"
            ak="lIXC8d0tvmYlv7FjVKIhrCRo5pDbf8RO"
            :center="center"
            :zoom="zoom"
            @ready="handler"
          >
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <bm-marker
              :position="{ lng: 120.118096, lat: 30.340524 }"
              :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE"
            >
              <bm-label
                content="杭州融健科技有限公司"
                :labelStyle="{ color: 'red', fontSize: '1px' }"
                :offset="{ width: 19, height: 0 }"
              />
            </bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
export default {
  components: {
    Head,
    Foot,
    BaiduMap,
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
    };
  },
  mounted() {},
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 120.118096;
      this.center.lat = 30.340524;
      this.zoom = 19;
      map.enableScrollWheelZoom();
    },
  },
};
</script>
<style scoped lang="scss">
.center {
  margin: 0 auto;
  margin-bottom: 90px;
}
.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}
.title {
  margin-top: 50px;
  margin-bottom: 50px;
  .title_1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #272727;
  }
  .title_xian {
    margin: 0 auto;
    margin-top: 5px;
    width: 57px;
    height: 2px;
    background: #272727;
  }
  .title_2 {
    margin-top: 5px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    color: #828282;
  }
}
.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}
.head1 {
  text-align: center;
  background: #ffffff;
}
.nei {
  width: 1181px;
  margin: 0 auto;
  margin-bottom: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  .left {
    color: #ffffff;
    .title {
      text-align: left;
      margin-top: 39px;
      width: 244px;
      height: 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #191919;
    }
    .text {
      width: 373px;
      height: 136px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3e3e3e;
      line-height: 30px;
      margin-top: 31px;

      text-align: left;
    }
  }
  .n_center {
    width: 507px;
    height: 243px;
    .bm-view {
      margin-top: 38px;
      width: 100%;
      height: 243px;
    }
  }

}
</style>
