<template>
  <!-- 头部 -->
  <div class="head">
    <!-- <div style="font-size: 2rem; padding: 20px;margin-right:55rem">加砖信息</div> -->
    <div>
      <img src="../assets/3.c85c8b2.png" alt="" />
    </div>
    <div class="xuanz">
      <div
        v-for="(item, index) in biaoti"
        :key="index"
        @click="yemian(item)"
        class="son"
        :class="{ zt: item.isActive }"

      >
        {{ item.name }}
        <div :class="{ xuanzhon: item.isActive }"  class="xian"></div>
      </div>
      <router-link to="platforms" target="_blank">
        <img src="../assets/组 10.png" alt="" style=""  @click="go"/>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "head",
  data() {
    return {
      biaoti: [
        //顶部导航栏的内容
        { id: 1, name: "首页", isActive: true, go: "index" },
        { id: 2, name: "关于我们", isActive: false, go: "about" },
        { id: 3, name: "新闻资讯", isActive: false, go: "news" },
        { id: 4, name: "产品体系", isActive: false , go: "product"},
        { id: 5, name: "智能硬件", isActive: false , go: "hardware"},
        { id: 6, name: "联系我们", isActive: false , go: "contact"},
      ],
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    this.b = window.sessionStorage.getItem("id");
    if (this.$route.params.id) {
      let i = this.$route.params.id;
      window.sessionStorage.setItem("id", i);
      this.yemian(this.biaoti[i - 1]);
    } else if(this.b) {
      this.yemian(this.biaoti[this.b - 1]);
    }

  },
  methods: {
    go(){

    },
    yemian(i) {
      //顶部导航栏

      this.biaoti.forEach(function (obj) {
        obj.isActive = false;
      });
      i.isActive = !i.isActive;

      this.$router.push({ name: i.go, params: { id: i.id } });
    },
  },
};
</script>
<style scoped>
.head {
  height: 83px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10rem;
}

.son {
  width: 65px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #272727;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;

}
.xuanz {
  display: flex;
  flex-wrap: nowrap;
}
.xuanzhon {
  width: 55px;
height: 3px;
background: #F47506;
border-radius: 2px;
margin:0 auto ;
margin-top: 5px;
}
.xian{
    width: 55px;
height: 3px;

border-radius: 2px;
margin:0 auto ;
margin-top: 5px;

}
.zt{

font-size: 16px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #262626;
}
/* .son:hover {
  background-color: #0665da;
} */
</style>
